import { LocationDetailsType, SurchargesList } from '../../shared.types';

export enum TripTypes {
  OneWay = 0,
  RoundTrip = 1,
  Hourly = 2,
}

export enum ProgramPaymentMethods {
  PassengerPays = 0,
  DirectlyBilled = 1,
}

export interface BookRideFormFields {
  TRIP_TYPE: TripTypes | number;
  PICKUP_ADDRESS?: Partial<LocationDetailsType>;
  DROPOFF_ADDRESS?: Partial<LocationDetailsType>;
  PICKUP_DATE: string;
  PICKUP_TIME: string;
  RETURN_PICKUP_DATE?: string | null;
  RETURN_PICKUP_TIME?: string | null;
  ESTIMATED_TIME_IN_HOURS?: string | null;
  PASSENGERS_AMOUNT: string;
  LUGGAGE_AMOUNT: string;
  SERVICE_PROVIDER_VEHICLE_CLASS?: Partial<ServiceProviderVehicleClassType>;
  FIRST_NAME: string;
  LAST_NAME: string;
  EMAIL: string;
  PHONENUMBER: string;
  FLIGHT_TYPE: FlightTypes;
  AIRLINE?: { label?: string; value?: string };
  ARRIVAL_FLIGHT?: string;
  FLIGHT_TAIL_NUMBER?: string;
  SPECIAL_REQUESTS?: string | null;
  TIP_AMOUNT?: number;
  RIDER?: Partial<RiderResponse>;
  PAYMENT?: PaymentResponse;
  PROGRAM_PAYMENT_METHOD?: ProgramPaymentMethods;
  BUSINESS_TRAVEL_ACCOUNT?: BusinessAccountType | null;
  BUSINESS_TRAVEL_PROGRAM?: BusinessTravelProgram | null;
  QUOTE_ID?: string;
  QUOTE_ID_LEG2?: string;
  AUTO_GRATUITY: {
    tabValue: string | null | false;
    percentage: string | null | false;
  };
}

export const BookRideFieldNames: Record<keyof BookRideFormFields, string> = {
  TRIP_TYPE: 'TRIP_TYPE',
  PICKUP_ADDRESS: 'PICKUP_ADDRESS',
  DROPOFF_ADDRESS: 'DROPOFF_ADDRESS',
  PICKUP_DATE: 'PICKUP_DATE',
  PICKUP_TIME: 'PICKUP_TIME',
  RETURN_PICKUP_DATE: 'RETURN_PICKUP_DATE',
  RETURN_PICKUP_TIME: 'RETURN_PICKUP_TIME',
  ESTIMATED_TIME_IN_HOURS: 'ESTIMATED_TIME_IN_HOURS',
  PASSENGERS_AMOUNT: 'PASSENGERS_AMOUNT',
  LUGGAGE_AMOUNT: 'LUGGAGE_AMOUNT',
  SERVICE_PROVIDER_VEHICLE_CLASS: 'SERVICE_PROVIDER_VEHICLE_CLASS',
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  EMAIL: 'EMAIL',
  PHONENUMBER: 'PHONENUMBER',
  FLIGHT_TYPE: 'FLIGHT_TYPE',
  AIRLINE: 'AIRLINE',
  ARRIVAL_FLIGHT: 'ARRIVAL_FLIGHT',
  FLIGHT_TAIL_NUMBER: 'FLIGHT_TAIL_NUMBER',
  SPECIAL_REQUESTS: 'SPECIAL_REQUESTS',
  TIP_AMOUNT: 'TIP_AMOUNT',
  RIDER: 'RIDER',
  PAYMENT: 'PAYMENT',
  PROGRAM_PAYMENT_METHOD: 'PROGRAM_PAYMENT_METHOD',
  BUSINESS_TRAVEL_ACCOUNT: 'BUSINESS_TRAVEL_ACCOUNT',
  BUSINESS_TRAVEL_PROGRAM: 'BUSINESS_TRAVEL_PROGRAM',
  QUOTE_ID: 'QUOTE_ID',
  QUOTE_ID_LEG2: 'QUOTE_ID_LEG2',
  AUTO_GRATUITY: 'AUTO_GRATUITY',
};

export interface LatLng {
  lat: number;
  lng: number;
}

export enum FlightTypes {
  Commercial = 0,
  Private = 1,
}

export type ServiceProviderVehicleClassType = {
  id?: string; // the quote ID
  serviceProviderVehicleClassId: string;
  name: string;
  serviceProviderVehicleClassType: string;
  description: string;
  services: string;
  image: string;
  passengers?: number;
  bags?: number;
  priority: number;
  tax: number;
  duration: number;
  tip: number;
  subTotal: number;
  total: number;
  totalGeneral: number;
  subTotalGeneral: number;
  surchargesList: SurchargesList[];
  roundtripLeg2: ServiceProviderVehicleClassType | null;
  failedToLoad?: boolean;
};

export type VehicleClassTypeResponse = ServiceProviderVehicleClassType[];

export interface RequestVehicleClassType {
  TripType: number;
  Locations: {
    AddressType: number;
    Address: string;
    City: string;
    State: string;
    PostalCode: string;
    CountryCode: string;
    Latitude: number;
    Longitude: number;
  }[];
  NumberOfPassengers: number;
  AmountLuggage?: number;
  PickUpDateTime: string;
  ReturnDateTime?: string;
  BusinessProgramId?: string;
  EstimatedTimeInHours?: number;
}

export type RiderRequest = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

export interface RiderResponse {
  id: string;
  updatedAt: string;
  createdAt: string;
  deletedAt?: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export type PaymentMethodResponseByKaptyn = {
  id: string;
  defaultPaymentMethod: boolean;
  brand: string;
  expMonth: number;
  expYear: number;
  number: string;
  stripePaymentMethodId: string;
};

export interface PaymentResponse {
  id?: string;
  object?: string;
  automatic_payment_methods?: string;
  cancellation_reason?: string;
  client_secret?: string;
  created?: number;
  description?: string;
  last_setup_error?: string;
  livemode?: boolean;
  next_action?: string;
  payment_method?: string;
  payment_method_configuration_details?: string;
  payment_method_types?: string[];
  status?: string;
  usage?: string;
  payment_method_by_kaptyn?: PaymentMethodResponseByKaptyn;
}

export type BookRideRequestLocationType = {
  addressType?: number;
  address?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  countryCode?: string;
  airportCode?: string | null;
  flightNumber?: string | null;
  airlineId?: string | null;
  flightType?: number | null;
  latitude?: number;
  longitude?: number;
  googlePlacesId?: string;
}[];

export interface BookReservationRequestType {
  tripType: number;
  riderId: string | null;
  userId?: string;
  paymentMethodId?: string;
  medium?: string | null;
  source?: string | null;
  campaign?: string | null;
  locations?: BookRideRequestLocationType;
  pickUpDateTime: string;
  returnDateTime?: string;
  estimatedTimeInHours?: number | null;
  serviceProvider_VehicleClassId: string;
  passengers: number;
  luggage: number;
  bookingSource: number;
  notes?: string | null;
  businessProgramId?: string;
  programPaymentMethod?: ProgramPaymentMethods;
  programPassId?: string;
  quoteId: string;
  extraGratuityPercentage: number | null;
}

export interface BookRideResponseType {
  id: string;
  rideNumber: string;
  startLocation: number[];
  startLocationDescription: string;
  destLocation: number[];
  destLocationDescription: string;
  estimatedTimeInHours?: number;
  airlineId: string;
  flightType: number;
  flightNumber: string;
  scheduledTime: string;
  subtotal: number;
  tip: number;
  tax: number;
  total: number;
  status: number;
  cancelNotes: string;
  cancelReason: string;
  rideSurcharges: {
    id: string;
    surchargeName: string;
    amount: number;
  }[];
  region: string;
  serviceProvider_vehicleClassId: string;
  vehicleClassName: string;
}

export interface BookReservationResponseType {
  id: string;
  businessProgramId: string;
  tripType: number;
  riderId: string;
  userId?: string;
  reservationNumber: string;
  programPaymentMethod: number;
  programPassId: string;
  bookingSource: number;
  notes: string;
  rides: BookRideResponseType[];
  rider: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };
}

export enum ProgramTypes {
  Concierge = 0,
  BusinessTravel = 1,
  Pass = 2,
}

export enum ProgramPassTypes {
  Individual = 0,
  General = 1,
}

export enum PassCoverageTypes {
  HalfAmount = 0,
  FullAmount = 1,
  GuestPays = 2,
}

export type ProgramAllowedAddressType = {
  location: number[];
  address: string;
  city: string;
  state: string;
  postalCode: string;
  googlePlacesId: string;
  countryCode: string;
  allowPickUp: boolean;
  allowDropOff: boolean;
  airportCode?: string;
};

export type ProgramPassType = {
  id: string;
  externalReference?: string;
  redemptionsCountAllowed?: number;
  totalAmountAllowed?: number;
  timesRedeemed: number;
  mongoDbId?: string;
  businessProgram: {
    allowedAddresses: ProgramAllowedAddressType[] | undefined;
    businessAccountId: string;
    programType: ProgramTypes;
    name: string;
    passengerPays: boolean;
    directlyBilledOrganization: boolean;
    paidByInvoice: boolean;
    organizationPaymentMethodId?: string;
    heroImageUrl: string;
    cmsTitle: string;
    cmsSubtitle: string;
    cmsInformationMessage: string;
    passType: ProgramPassTypes;
    passCoverageType: PassCoverageTypes;
    passRedeemValidFrom: Date;
    passRedeemValidTo: Date;
    passRideValidFrom: Date;
    passRideValidTo: Date;
    allowedVehicleClassesId: string[] | undefined;
    allowedTripTypes: TripTypes[];
    id: string;
    autoGratuityPercentage: number | null;
  };
};

export type BusinessAccountType = { id?: string; name?: string };

export type BusinessTravelProgram = {
  id?: string;
  name?: string;
  passengerPays?: boolean;
  directlyBilledOrganization?: boolean;
  paidByInvoice?: boolean;
  autoGratuityPercentage?: number | null;
};

export type ServiceProviderWithVehicleClasses = {
  id: string;
  name: string;
  serviceProvidersVehicleClassIds: string[];
  serviceProviderVehicleClasses: ServiceProviderVehicleClassType[];
};

export type GetServiceProviderAndVehicles = {
  Latitude: number;
  Longitude: number;
  BusinessProgramId?: string;
};
