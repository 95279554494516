import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import TabbedTips from './TabbedTips';
import { BookRideFieldNames } from '../../../../types';
import ErrorMessage from '../../../../../../components/shared/formFieldRelated/ErrorMessage';

interface Props {
  allowDeselection?: boolean;
}

const WrapperRHFTabbedTips: React.FC<Props> = ({ allowDeselection }) => {
  const { control, trigger, setValue, watch } = useFormContext();
  const watchedAutoGratuity = watch(BookRideFieldNames.AUTO_GRATUITY);

  return (
    <>
      <Controller
        name={BookRideFieldNames.AUTO_GRATUITY}
        control={control}
        render={({ field: { value, onChange } }): React.ReactElement => (
          <Box>
            <TabbedTips
              value={watchedAutoGratuity}
              tabs={[
                {
                  label: '15%',
                  value: '15',
                  dataCy: '15%',
                },
                {
                  label: '20%',
                  value: '20',
                  dataCy: '20%',
                },
                {
                  label: '25%',
                  value: '25',
                  dataCy: '25%',
                },
              ]}
              onChange={(position: string | boolean): void => {
                const parsedPosition = position;

                onChange({
                  tabValue: parsedPosition,
                  percentage: position === 'custom' ? null : parsedPosition,
                });

                if (position !== 'custom') {
                  trigger(BookRideFieldNames.AUTO_GRATUITY);
                }
              }}
              onClear={(): void => {
                setValue('AUTO_GRATUITY.percentage', null);

                trigger(BookRideFieldNames.AUTO_GRATUITY);
              }}
              allowCustomValue
              allowDeselection={allowDeselection}
            />
            <ErrorMessage name="AUTO_GRATUITY.percentage" />
          </Box>
        )}
      />
      {/*<ErrorMessage name={`${BookRideFieldNames.AUTO_GRATUITY}.percentage`} />*/}
    </>
  );
};

export default WrapperRHFTabbedTips;
