import { Box, Stack, Typography } from '@mui/material';
import { FONT_FAMILY, TYPOGRAPHY } from '../../../../theme/typography';
import React, { ReactElement, useMemo } from 'react';
import useModal from '../../../../HookComponents/useModal';
import AppButton from '../../../../components/shared/AppButton';
import { useFormContext } from 'react-hook-form';
import { BookRideFieldNames } from '../../types';
import { formatToUSD } from '../../../../utils/MoneyConvertion';
import CostBreakdown from '../../../../components/CostBreakdown';
import { SurchargesList } from '../../../../shared.types';

const Total = ({ showPrices = true }: { showPrices: boolean }): ReactElement => {
  const { Modal, open, openModal, closeModal } = useModal();

  const { watch } = useFormContext();

  const [selectedVehicle, extraAutoGratuity, businessTravelProgram] = watch([
    BookRideFieldNames.SERVICE_PROVIDER_VEHICLE_CLASS,
    BookRideFieldNames.AUTO_GRATUITY,
    BookRideFieldNames.BUSINESS_TRAVEL_PROGRAM,
  ]);

  const getPercentage = (baseNumber: number, percentageAmount: number): number => {
    return (baseNumber * percentageAmount) / 100;
  };

  const totalLeg1 = useMemo(() => {
    if (showPrices) {
      if (
        typeof selectedVehicle?.total === 'number' &&
        typeof selectedVehicle?.subTotal === 'number'
      ) {
        const totalGratuityPercentage =
          (businessTravelProgram?.autoGratuityPercentage || 0) +
          parseInt(
            extraAutoGratuity.percentage >= 0 && extraAutoGratuity.percentage <= 100
              ? extraAutoGratuity.percentage || 0
              : 0,
          );
        const calculatedExtraGratuity = totalGratuityPercentage
          ? getPercentage(selectedVehicle.subTotal, totalGratuityPercentage)
          : 0;

        return selectedVehicle.total + calculatedExtraGratuity;
      }
    } else {
      return '* Included in package';
    }

    return 0;
  }, [
    businessTravelProgram?.autoGratuityPercentage,
    extraAutoGratuity.percentage,
    selectedVehicle.subTotal,
    selectedVehicle.total,
    showPrices,
  ]);

  return (
    <Box
      sx={{
        p: '20px',
      }}
      data-cy="total-card"
    >
      {showPrices && (
        <Modal
          isOpen={open}
          onClose={closeModal}
          title="Total breakdown
  "
          dialogStyles={{
            maxWidth: '444px',
            margin: '0 auto',
          }}
          dataCy="total-breakdown"
        >
          <CostBreakdown
            leg1Data={[
              {
                label: 'Estimated total',
                value: totalLeg1,
                isTitle: true,
                valueDataCy: 'totalLeg1',
              },
              {
                label: selectedVehicle?.name,
                value: selectedVehicle.subTotal,
              },
              { label: 'Taxes and fees', value: '', isTitle: true },
              { label: 'Estimated taxes', value: selectedVehicle.tax },

              ...(selectedVehicle.surchargesList?.map((surcharge: SurchargesList) => {
                return {
                  label: surcharge.description,
                  value: surcharge.amount,
                };
              }) || []),

              { label: 'Tip', value: selectedVehicle.tip, isVisible: selectedVehicle.tip > 0 },
              {
                label: `Gratuity** (${businessTravelProgram?.autoGratuityPercentage}%)`,
                value: getPercentage(
                  selectedVehicle.subTotal,
                  businessTravelProgram?.autoGratuityPercentage,
                ),
                isVisible: Boolean(businessTravelProgram?.autoGratuityPercentage),
              },
              {
                label: `Additional gratuity ${extraAutoGratuity.percentage}%`,
                value: getPercentage(selectedVehicle.subTotal, extraAutoGratuity.percentage),
                isVisible: extraAutoGratuity.percentage,
              },
            ]}
            leg2Data={[
              {
                label: 'Estimated total',
                value: selectedVehicle.roundtripLeg2?.total ?? 0,
                isTitle: true,
                valueDataCy: 'totalLeg1',
              },
              {
                label: selectedVehicle?.name,
                value: selectedVehicle.roundtripLeg2?.subTotal ?? 0,
              },
              { label: 'Taxes and fees', value: '', isTitle: true },
              {
                label: 'Estimated taxes',
                value: selectedVehicle.roundtripLeg2?.tax ?? 0,
              },
              ...(selectedVehicle.roundtripLeg2?.surchargesList?.map(
                (surcharge: SurchargesList) => {
                  return {
                    label: surcharge.description,
                    value: surcharge.amount,
                  };
                },
              ) ?? []),

              {
                label: 'Tip',
                value: selectedVehicle.roundtripLeg2?.tip ?? 0,
                isVisible: (selectedVehicle.roundtripLeg2?.tip ?? 0) > 0,
              },
            ]}
          />

          {businessTravelProgram?.autoGratuityPercentage && (
            <Typography
              sx={{
                fontSize: '12px',
                fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
                mt: '10px',
              }}
            >
              **A {businessTravelProgram?.autoGratuityPercentage}% automatic gratuity is applied to
              your ride and goes 100% to your driver as a thank you for their great service.
            </Typography>
          )}
          <AppButton
            title="Close"
            variant="outlined"
            additionalSx={{
              mt: '20px',
            }}
            onClick={closeModal}
            data-cy="close-button"
          />
        </Modal>
      )}

      <Stack direction="row" justifyContent="space-between">
        <Box
          sx={{
            ...TYPOGRAPHY.title4,
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          Total
          {showPrices && (
            <Box
              sx={{
                width: '15px',
                height: '15px',
                backgroundColor: '#464A4F',
                color: 'white',
                borderRadius: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '12px',
                fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
                ml: '10px',
                cursor: 'pointer',
              }}
              onClick={openModal}
              data-cy="total-info-btn"
            >
              i
            </Box>
          )}
        </Box>

        <Typography
          sx={{
            ...TYPOGRAPHY.title4,
          }}
          data-cy="total-cost"
        >
          {formatToUSD(totalLeg1 + (selectedVehicle.roundtripLeg2?.total || 0))}
        </Typography>
      </Stack>

      <Typography
        sx={{
          ...TYPOGRAPHY.tinyText,
          mt: '20px',
        }}
      >
        By booking your reservation, you agree to Kaptyn&apos;s Terms of Use and consent to its
        Privacy Policy.
      </Typography>
    </Box>
  );
};

export default Total;
