import { FormProvider, useForm } from 'react-hook-form';
import {
  BookRideFormFields,
  BookRideRequestLocationType,
  BookReservationRequestType,
  BookReservationResponseType,
  FlightTypes,
  PassCoverageTypes,
  ProgramPaymentMethods,
  TripTypes,
} from './bookRide/types';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import React, {
  FC,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { useBookRidePageSchema } from './bookRide/useBookRidePageSchema';
import { useAuthContext } from '../auth/useAuthContext';
import { LocationDetailsType, UserType } from '../shared.types';
import { ENDPOINTS } from '../services/Endpoints';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { ServiceTypes, useBaseService } from '../services/useBaseService';
import { LoadingComponent } from '../components/shared/LoadingComponent';
import { CheckoutSectionType } from './bookRide/steps/step3/types';
import { initialSectionValues } from './bookRide/steps/step3/sectionHelpers';
import { useProgramPassContext } from './ProgramPassContext';
import { trackPurchase } from 'utils/googleAnalyticsUtils';
import * as yup from 'yup';

interface Props {
  setCheckoutSections: React.Dispatch<
    React.SetStateAction<CheckoutSectionType[]>
  >;
  formInitialValues: (user: UserType | null) => BookRideFormFields;
}

export const SchemaContext = React.createContext({
  schema: yup.object(),
});

const FormStateWrapper: FC<PropsWithChildren<Props>> = ({
  setCheckoutSections,
  children,
  formInitialValues,
}): ReactElement => {
  const { schema } = useBookRidePageSchema();
  const { user, updateUserClaims } = useAuthContext();
  const navigate = useNavigate();
  const { post } = useBaseService({ type: ServiceTypes.RideAPI });
  const location = useLocation();
  const [lastProgramPassId, setLastProgramPassId] = useState<string>();
  const {
    programPass,
    isLoading: isLoadingPass,
    loadPassDetails,
  } = useProgramPassContext();

  const match = useMatch('/pass/:code/*');

  const code = match?.params.code;

  const methods = useForm<BookRideFormFields>({
    defaultValues: formInitialValues(user),
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    async function loadProgramPass(): Promise<void> {
      await loadPassDetails(code as string);
    }

    if (code && !programPass && !isLoadingPass) {
      loadProgramPass();

      const matchIncompletePassStep1_1 = /pass\/[^/]+$/.test(location.pathname); //checks if the url exact matches: 'pass/:code'
      const matchIncompletePassStep1_2 = /pass\/[^/]+\/$/.test(
        location.pathname,
      ); //checks if the url exact matches: 'pass/:code/'
      if (matchIncompletePassStep1_1 || matchIncompletePassStep1_2) {
        navigate(
          `${location.pathname}${matchIncompletePassStep1_1 ? '/step1' : matchIncompletePassStep1_2 ? 'step1' : ''}`,
          { replace: true },
        );
      }
    } else if (!code) {
      setLastProgramPassId(undefined);
    }
  }, [
    code,
    isLoadingPass,
    loadPassDetails,
    location.pathname,
    methods,
    navigate,
    programPass,
    location,
  ]);

  useEffect(() => {
    if (programPass && programPass.id !== lastProgramPassId) {
      setLastProgramPassId(programPass.id);
      let minDate = dayjs(programPass.businessProgram.passRideValidFrom);

      if (minDate < dayjs()) minDate = dayjs();

      const rideValidTo = dayjs(programPass.businessProgram.passRideValidTo);
      if (rideValidTo < dayjs()) return navigate('/');

      methods.setValue('PICKUP_DATE', minDate.format('YYYY-MM-DD'));
      if (
        programPass.businessProgram.passCoverageType ===
        PassCoverageTypes.FullAmount
      ) {
        methods.setValue(
          'PROGRAM_PAYMENT_METHOD',
          ProgramPaymentMethods.DirectlyBilled,
        );
        methods.setValue('PAYMENT', undefined);
      } else if (
        programPass.businessProgram.passCoverageType ===
        PassCoverageTypes.GuestPays
      ) {
        methods.setValue(
          'PROGRAM_PAYMENT_METHOD',
          ProgramPaymentMethods.PassengerPays,
        );
      }
    }
  }, [programPass, lastProgramPassId, methods, navigate, location.pathname]);

  useEffect(() => {
    if (user) {
      methods.setValue('FIRST_NAME', user?.first_name ?? '');
      methods.setValue('LAST_NAME', user?.last_name ?? '');
      methods.setValue('EMAIL', user?.email ?? '');
      methods.setValue('PHONENUMBER', user?.phone_number ?? '');
    }
  }, [updateUserClaims, methods, user, user?.first_name]);

  const submit = async (fields: BookRideFormFields): Promise<void> => {
    const urlQueryParams = new URLSearchParams(location.search);
    const requestData: BookReservationRequestType = {
      tripType: fields.TRIP_TYPE,
      riderId: fields?.RIDER?.id ?? null,
      paymentMethodId: fields.PAYMENT?.payment_method_by_kaptyn?.id,
      pickUpDateTime: `${fields.PICKUP_DATE}T${fields.PICKUP_TIME}:00`,
      serviceProvider_VehicleClassId:
        fields.SERVICE_PROVIDER_VEHICLE_CLASS?.serviceProviderVehicleClassId ??
        '',
      passengers: parseInt(fields.PASSENGERS_AMOUNT),
      luggage: parseInt(fields.LUGGAGE_AMOUNT),
      bookingSource: 2,
      notes: fields.SPECIAL_REQUESTS,
      businessProgramId: programPass?.businessProgram.id,
      programPaymentMethod: fields.PROGRAM_PAYMENT_METHOD,
      programPassId: programPass?.id,
      medium: urlQueryParams.get('medium'),
      source: urlQueryParams.get('source'),
      campaign: urlQueryParams.get('campaign'),
      quoteId: fields.QUOTE_ID as string,
      extraGratuityPercentage: !fields.AUTO_GRATUITY.percentage
        ? null
        : parseInt(fields.AUTO_GRATUITY.percentage),
    };

    const airlineFields = {
      airlineId: fields?.AIRLINE?.value ?? null,
      flightType: fields?.FLIGHT_TYPE ?? null,
      flightNumber:
        fields?.FLIGHT_TYPE === FlightTypes.Commercial
          ? fields?.ARRIVAL_FLIGHT ?? null
          : fields?.FLIGHT_TAIL_NUMBER ?? null,
    };

    const locations: BookRideRequestLocationType = [
      {
        ...(fields.PICKUP_ADDRESS as Required<LocationDetailsType>),
        ...(fields.PICKUP_ADDRESS?.airportCode && airlineFields),
      },
    ];

    if (
      fields.TRIP_TYPE === TripTypes.OneWay ||
      fields.TRIP_TYPE === TripTypes.RoundTrip
    ) {
      locations.push({
        ...fields.DROPOFF_ADDRESS,
        ...(fields.DROPOFF_ADDRESS?.airportCode && airlineFields),
      });
    }

    if (fields.TRIP_TYPE === TripTypes.RoundTrip) {
      requestData['returnDateTime'] = dayjs(
        `${fields.RETURN_PICKUP_DATE} ${fields.RETURN_PICKUP_TIME}`,
      ).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    }

    if (fields.TRIP_TYPE === TripTypes.Hourly) {
      requestData['estimatedTimeInHours'] = parseInt(
        fields.ESTIMATED_TIME_IN_HOURS ?? '0',
      );
    }
    requestData['locations'] = locations;

    if (fields.BUSINESS_TRAVEL_PROGRAM || programPass) {
      requestData['userId'] = user?.user_id;
    }

    const businessProgramId =
      programPass?.businessProgram.id || fields.BUSINESS_TRAVEL_PROGRAM?.id;

    const endpointUrl = businessProgramId
      ? ENDPOINTS.BookProgramRide(businessProgramId)
      : ENDPOINTS.BookRide;

    const confirmationData = await post<
      BookReservationResponseType,
      BookReservationRequestType
    >(endpointUrl, Boolean(user), requestData);

    confirmationData?.rides.forEach((ride) => {
      trackPurchase(ride, confirmationData);
    });

    setCheckoutSections(initialSectionValues);

    navigate('/book-ride/confirmation', {
      state: {
        reservationNumber: (confirmationData as BookReservationResponseType)
          ?.reservationNumber,
        riderEmail: (confirmationData as BookReservationResponseType).rider
          ?.email,
        passId: programPass?.id,
      },
    });
  };

  if ((code && !programPass) || isLoadingPass) return <LoadingComponent />;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(submit)}
        style={{
          width: '100%',
        }}
      >
        <SchemaContext.Provider
          value={{
            schema,
          }}
        >
          {children}
        </SchemaContext.Provider>
      </form>
    </FormProvider>
  );
};

export default FormStateWrapper;
